import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Circle from "../resources/images/profile_page/grey-circle.svg";
import { Row, Col, Container } from "styled-bootstrap-grid";
import theme from "../styles/theme";
import fonts from "../styles/fonts";
import { BLOCKS } from "@contentful/rich-text-types";

const WhiteBackground = styled.div`
  width: 100%;
  padding: 80px 0 0;
  background: ${theme.colors.white};
  box-sizing: border-box;
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: 160px 80px 0;
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding: 160px 160px 0;
  }
`;

const ContainerStyled = styled(Container)`
  background: url(${Circle}) no-repeat;
  background-size: 340px;
  background-position-x: calc(100% + 40px);
  background-position-y: 120px;
  max-width: 1400px !important;
  margin: 0 auto;
  padding-top: 40px;
  @media (min-width: ${theme.breakpoints.medium}) {
    background-size: 500px;
    background-position-x: calc(28%);
    background-position-y: 0px;
  }
`;

const Title = styled.h1`
  ${fonts.CircularMedium};
  font-size: 41px;
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 61px;
  }
  @media (min-width: ${theme.breakpoints.xl}) {
    padding-left: 30px;
  }
`;

const TitleCol = styled(Col)`
  margin-top: 48px;
  padding: 0 25px;
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: 0;
  }
`;
const TextCol = styled(Col)`
  margin-top: 44px;
  padding: 0 25px;
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: 0;
    margin-top: 32px;
  }
`;

const Text = styled.div`
  ${fonts.MaisonNeueBook};
  max-width: 630px;
  h2 {
    ${fonts.CircularMedium};
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 32px;
    padding-top: 20px;
    :first-child {
      padding-top: 0;
    }
  }
  h3 {
    ${fonts.CircularMedium};
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 4px;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 20px;
    :last-child {
      padding-bottom: 80px;
    }
  }
  @media (min-width: ${theme.breakpoints.medium}) {
    h2 {
      font-size: 32px;
      padding-bottom: 32px;
      padding-top: 20px;
      :first-child {
        padding-top: 0;
      }
    }
    h3 {
      font-size: 22px;
      line-height: 24px;
      padding-bottom: 4px;
    }
    p {
      font-size: 18px;
      line-height: 28px;
      padding-bottom: 40px;
      :last-child {
        padding-bottom: 80px;
      }
    }
  }
`;

const Vertical = styled.div`
  ${fonts.MaisonNeueDemi};
  font-size: 18px;
  line-height: 22px;
  border-bottom: 2px solid ${theme.colors.black};
  position: absolute;
  transform: rotate(270deg);
  right: -20px;
  top: 140px;
  display: none;
  @media (min-width: ${theme.breakpoints.medium}) {
    display: block;
  }
`;

const renderDocument = (document) => {
  const Text = ({ children }) => <p>{children}</p>;
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
    renderText: (text) =>
      text.split("<br>").flatMap((text, i) => [i > 0 && <br />, text]),
  };
  return documentToReactComponents(document, options);
};

const page = (props) => {
  const { page } = props.data;
  const { content, title, verticalText } = page;

  return (
    <Layout colorScheme={"dark"}>
      <WhiteBackground>
        <ContainerStyled>
          {/*<Vertical>{verticalText}</Vertical>*/}
          <Row>
            <TitleCol md={6}>
              <Title>{title}</Title>
            </TitleCol>
            <TextCol md={6}>
              <Text>{renderDocument(content.json)}</Text>
            </TextCol>
          </Row>
        </ContainerStyled>
      </WhiteBackground>
    </Layout>
  );
};

export default page;

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      id
      slug
      metaTitle
      metaDescription
      title
      verticalText
      content {
        json
      }
    }
  }
`;
